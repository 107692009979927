.top_right_overlay {
  overflow: hidden;
  pointer-events: none;
  position: absolute;
  top: 10px;
  right: 10px;
  margin-right: 2vw;
  margin-top: 1vh;
  color: white;
  -webkit-font-smoothing: auto;
  text-align: right;
  opacity: 0;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  /* or 17px */
  text-align: right;
}

.top_right_altitude {
  text-transform: uppercase;
}

.top_right_overlay > p {
  margin: 0
}

.top_right_loader {
  overflow: hidden;
  pointer-events: none;
  position: absolute;
  top: 10px;
  right: 10px;
  margin-right: 2vw;
  margin-top: 1vh;
  color: rgb(255, 255, 255);
  -webkit-font-smoothing: auto;
  text-align: right;
  opacity: 0;
  
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
}

.top_right_credits_overlay {
  pointer-events: none;
  position: absolute;
  top: 10px;
  right: 10px;
  margin-right: 2vw;
  margin-top: 1vh;
  color: white;
  -webkit-font-smoothing: auto;
  z-index: 2;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  /* or 17px */
  text-align: right;
}