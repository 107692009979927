/* @import url("https://rsms.me/inter/inter.css"); */

@font-face {
  font-family: 'Advanced Pixel-7';
  src: url("./advanced_pixel-7.ttf")
}
@font-face {
  font-family: 'Orange Kid';
  src: url("./orange_kid.ttf")
}

.viewport_wrapper {
  pointer-events: none;
  color: rgb(255, 255, 255);
  position: absolute;
  width: 100vw;
  height: 100vh;
}

.viewport_separator {
  pointer-events: none;
  position: absolute;
  width: 100vw;
  height: 50vh;
}


.viewport_wrapper > h1 {
  position: absolute;
  font-size: 60px;
  margin: 0;
}

.viewport_wrapper > h2 {
  position: absolute;
  font-size: 40px;
}

.viewport_wrapper > span {
  position: absolute;
  top: 40vh;
  font-size: 25px;
  width: '50vw';
}

.scrollbar_container {
  overflow: hidden;
  position: absolute;
  top: 100px;
  right: 35px;
  width: 4px;
  height: calc(100% - 120px);
  opacity: 0
}
.scrollbar {
  z-index: 3;
  width: 100%;
  height: 100%;
  /* background: linear-gradient(purple, navy, navy, navy, blue, cyan,green, yellow, black, orange); */
  background: linear-gradient(0deg, #DA683E 0%, #D47745 7%, #C69F58 20%, #AFE076 38%, #A7F982 45%, #9FF887 48%, #8AF595 52%, #68F1AC 58%, #38EBCD 64%, #30EAD3 65%, #30E5D2 67%, #30D9D2 69%, #30C4D2 71%, #30A7D2 73%, #3082D2 76%, #3054D2 78%, #311CD2 80%, #221198 88%, #150868 96%, #110556 100%);
}

/*hide scroll*/
.App > div > div {
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow-y: none;
}

.App > div > div::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}

.scrollglow {
  position: relative;
  width: 100%;
  height: calc(100% /4);
  bottom: 0px;
  z-index: 1;
  box-shadow: 0px 0px 50px rgb(255, 255, 255);
  color:rgb(0, 0, 0, 0)
}

.scrollglow::before {
  box-shadow: 0px 0px 50px rgb(255, 255, 255);
  -moz-box-shadow: 0px 0px 50px rgb(255, 255, 255);
  -webkit-box-shadow: 0px 0px 50px rgb(255, 255, 255);
}

.debugscroll {
  position: absolute;
  top: 10px;
  right: 110px;
  border-style: dashed;
  color: red;
  font-size: xx-large;
}

.glitched_text {  
  margin: 0;
  font-kerning: none;
  text-transform:uppercase;
  letter-spacing: 2px;
}

.blocked_scroll {
  overflow: hidden;
}
h1 {
  display: none;
  pointer-events: none;
}
