.audio_player {
  position: absolute;
  bottom: 64px;
  left: 64px;
  display: flex;
  flex-direction: row;
  align-items: bottom;
  opacity: 0;
  pointer-events: none;
}

.audio_button{
  color: white;
  padding-left: 8px;
}

.audio_text {
  color: white;
  min-width: 20vw;
  min-height: 20px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  text-align: center;
  opacity: 0;

  position: absolute;
  top: calc(100% - 64px);
  left: 50%;
  transform: translate(-50%, -50%);
  text-transform: uppercase;
}

#equalizer {
  width: 32px;
  height: 32px;  
  /* border-color: red;
  border-style: dashed; */
  pointer-events: auto;
}

#equalizer_canvas {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}