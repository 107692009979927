

body {
  position: fixed;
  background-color: #101010;
  background: radial-gradient(circle at bottom center, #0a0a0a 0%, #080808 90%);
  /* font-family: Advanced Pixel-7; */
  font-family: Orange 'Inter';
  /* -webkit-font-smoothing: antialiased; */
}

.canvas {
  pointer-events: none;
  position: absolute !important;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
}

.view1 {
  position: absolute;
  width: 100%;
  height: 100%;
}

.view2 {
  position: absolute;
  bottom: 2em;
  left: 2em;
  width: calc(250px - 2em);
  height: calc(250px - 2em);
}

.container {
  position: relative;
  width: 100%;
  height: 100%;
}

/*remove vertical scrollbar */
.r3f_canvas > div > div::-webkit-scrollbar {
  display: none;  /* Safari and Chrome */
}

.r3f_canvas > div > div {
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */
  overflow: hidden;
}

.landing_page {
  width: 100%;
  height: 100%;
  background: radial-gradient(53.42% 97.25% at 50% 50%, #FBFBFB 8.85%, #FFFFFF 15.1%, #D4D5DB 69.27%);
}

.landing_page > img {
  position: absolute;
  top: -66%;
  left: -100%;
  width: 225%;
  height: 225%;
  filter: blur(16px);
}

.landing_page > div {
  color: white;
  position: absolute;
  top: 45%;
  left: 5vw;
  font-family: 'Medio';
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  line-height: 60px;
  text-transform: uppercase;  
}

.landing_page > p {
  position: absolute;
  top: 50vh;
  right: 5vw;
  font-family: 'Inter';
  font-weight: 700;
  font-size: 14px;
  text-transform: uppercase;  
}

.debug_meteo {
  color:red;
  position: absolute;
  top: 5px;
  left: 5px;
}
