/* @import url("https://rsms.me/inter/inter.css"); */

@font-face {
  font-family: "Inter";
  src: url("./fonts/Inter-VariableFont_slnt_wght.ttf")
}

@font-face {
  font-family: "Medio";
  src: url("./fonts/medio.otf")
}

.pointer_wrapper {
  position: absolute;
  right: -16px;
  top: 90%;
  width: 100%;
  height: 64px;
  border-style: dotted;
  border-width: 1px;
  border-color: red;
}

.chemicals_container {
  overflow: hidden;
  pointer-events: none;
  background-color: transparent;
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  top: 20px;
  width: 90%;
  margin: 0 36px;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 12px;
  z-index: 3;
}

.chemical_button {
  color: white;
  border-style: solid;
  border-width: 1px;
  padding: 4px;
  background-color: #0a0a0a;
}

.chemical_selected {
  color: #0a0a0a;
  background-color: white;
}

.scroll_vertical_line {
  overflow: hidden;
  z-index: 2;
  position: absolute;
  top: 0%;
  left: -1px;
  height: 100%;
  width: 1px;
  background-color: black;
}

.scroll_horizontal_line {
  overflow: hidden;
  z-index: 2;
  position: absolute;
  right: 20px;
  top: 32px;
  width: 32px;
  height: 1px;
  background-color: white;
  transition-property: width;
  opacity: 0
}

.chemical_information {
  overflow: hidden;
  position: absolute;
  top: 20%;
  right: 15%;
  max-width: 325px;
  color: black;
  display: flex;
  flex-direction: column;
  pointer-events: none;
}

.chemical_information .structure {
  height: 26vh;
}

.chemical_information .name_container {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
}

.chemical_information .common_name {
  font-family: 'Medio';
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 34px;
  text-transform: uppercase;
  letter-spacing: 0.05em;
}

.chemical_information .bond {
  font-family: 'Medio';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  /* text-transform: uppercase; */
}

.chemical_information .description {
  margin-top: 4vh;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
}

.microscope_section {
  min-height: 100px;
  min-width: 1000px;
  /* height: 20%; */
  width: 100%;
}

.meteorite_image {
  height: 80vh;
  left: 6%;
  overflow: hidden;
  pointer-events: none;
  position: absolute;
  top: 10vh;
  width: 50vw;
}

.meteorite_image>img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}