.loading_screen {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  text-align: center;
  color: white;
}

.loading_wrapper {
  pointer-events: none;
  width: 100%;
  height: 100%;
  margin: 0;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.intro_logo_wrapper {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  opacity: 0;
  width: 60vw;
  min-height: 40vh;
  z-index: 0;
  pointer-events: none;
  text-align: center;
}

.intro_logo {
  text-align: center;
  font-size: 10vh;
  color: black
}

.intro_logo>img {
  width: 100%;
}

.intro_text {
  min-height: 100px;
  font-family: 'Medio';
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 38px;
  text-align: center;
  text-transform: uppercase;
  padding-top: 3em;
  /* to create the distance between this text and the logo */
}

.intro_enter_container {
  display: flex;
  justify-content: center;
  margin-top: 10vh;
}

.intro_enter_button {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  color: black;
  pointer-events: auto;
  cursor: pointer;
  padding: 10px 20px;
  border: 1px solid #000000;
  border-radius: 40px;
}