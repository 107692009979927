.mobile_container {
  width: 100%;
  height: 100%;
  background: #E5E5E5;
  overflow: scroll;
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
}

.mobile_intro_container {
  height: 100%;
  width: 100%;
}

.mobile_intro {
  position: relative;
  height: 100%;
  width: 100%;
}

.mobile_intro_background {
  position: relative;
  object-fit: fill;
  height: 100vh;
  width: 100%;
}
.mobile_intro_background > img {
  height: 100%;
  width: 100%;
}

.mobile_only_available {  
  width: 100%;
  position: absolute;
  bottom: 32px;
  text-align: center;
  color: white;
}

.mobile_logo {
  position: absolute;
  top: 12px;
  left: 12px;
}

.mobile_logo > img {
  width: 100%;
}

.mobile_text {
  margin: 10%;
}

.mobile_sponsors_container {
  background-color: black;
  color: white;
}

.mobile_sponsors_container > p {  
  margin-left: 10%;
  margin-right: 10%;
  padding: 10%;
  text-align: center;
}

.mobile_sponsors {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.mobile_sponsors > img{
  margin-bottom: 32px;
}