.sponsors {
  display: flex;
  flex-direction: column;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
}

.sponsors {
  text-align: center;
}

.sponsor_container {
  text-align: center;
  margin-top: 2vw;
  width: 90%;
}

.sponsor_logos {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin: 32px;
}

.sponsor_logos > img {
  width: 200px;
  height: 100px;
  margin: 1vw;
}


.final_logo {
  width: 100%;
  margin: 16px;
  margin-top: 64px;
  height: 46vh;
  text-align: center;
  /* position: relative; */
  /* top: 33%; */
  /* left: 50%; */
  /* transform: translate(-50%, -50%); */
}

.final_logo > img {
  height: 100%;
}

.final_logo_container {
  height: 50%;
}

.sponsors_text {
  font-size: 16px;
  margin: 32px;
  text-transform: uppercase;
}