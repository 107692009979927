.scroll_to_continue {
  position: absolute;
  top: calc(100% - 64px);
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  text-align: center;
  opacity: 0;
  pointer-events: none;
}

.scroll_down_arrow {
  position: absolute;
  top: calc(100% - 64px);
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  text-align: center;
  opacity: 0;
  pointer-events: none;
  text-transform: uppercase;
}