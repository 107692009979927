.transition_before_microscope {
  overflow-y: scroll; /* este n faz aparecer o side scroll*/
  margin: 0;
  position: absolute;
  top: 20vh;
  left: 14vw;
  color: white;
  max-height: 70%;
  pointer-events: none;
  opacity: 0;
}

.tbm_text {
  color:white;
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
}