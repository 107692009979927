.top_left_logo {
  overflow: hidden;
  position: absolute;
  top: 0px;
  left: 0px;
  pointer-events: auto;

}

.top_left_logo_blue {
  overflow: hidden;
  position: absolute;
  top: 0px;
  left: 0px;
  pointer-events: auto;
  opacity: 0;
  z-index: 2;
}

.top_left_logo_black {
  overflow: hidden;
  position: absolute;
  top: 33px;
  left: 17px;
  pointer-events: auto;
  opacity: 0;
  z-index: 1;
}

.top_left_logo_container {
  overflow: hidden;
  pointer-events: none;
  opacity: 0;
  margin: 0;
  position: absolute;
  top: 0;
  left: 0;
  /* transform: translate(-50%, -50%); */
  color: black;
  height: 100%;
}

.top_left_logo_text {
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;

  margin: 0;
  position: absolute;
  top: 50%;
  left: 39%;
  transform: translate(-50%, -50%);
}

.top_left_close_button {
  pointer-events: auto;
    font-size: 24px;
    margin: 0;
    position: absolute;
    bottom: 20%;
    left: 8%;
}

.show_top_left_text {
  display: block;
}