.credits {
    height: 100vh;
    pointer-events: auto;
    z-index: 1;
}

.video_overlay {
    /* overflow: hidden;
    padding-bottom: 56.25%;
    position: relative; 
    height: 0; */

}

.video_overlay>video {
    object-fit: cover;
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
}

.video_overlay iframe {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
}

.credits_text {
    z-index: 2;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    color: black;
    font-size: 2em;
}

.credits_person {
    position: absolute;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 25px;
    text-transform: uppercase;
    color:white;
    width: 300px;
    opacity: 0;
}

.credits_person > p {
    text-align: left;
    margin: 0;
}

.credits_person > span {
    opacity: 0.4;
    position: absolute;
    right: 0;
}